<template>
  <div class="examples">
    <!-- <BasicTableExample heading="Targets" /> -->
    <div class="actions">
      <PassportButton class="new-target" to="/create-target" label="Create new target" />
      <PassportButton class="new-target" variant="outline" @click="showModal = true" label="Create new group" />
      <PassportButton class="new-target" variant="outline" @click="downloadQRs" label="Download All QR Codes" />
    </div>
    <PassportModal disableClosingOnMask v-model="showModal">
      <div class="create-group">
        <span class="modal-heading">Create New Group</span>
        <div>
          <span class="input-header"> group name* </span>
          <PassportInput v-model="groupName" class="group-title" placeholder="Type your group name..." />
          <span
            :class="{
              'input-instructions': !isError,
              'input-instructions__error': isError,
            }"
          >
            Give this stamp group a name.
          </span>
        </div>
        <div class="stamp-uploader">
          <p class="input-header">Stamp Image*</p>
          <PassportImageUploader
            ref="stampImage"
            class="image-upload"
            key="instance-0"
            :imageWidth="350"
            :imageHeight="200"
            :maxFileSize="10000000"
            :presignedUrlParams="{ type: 'book', item: 'cover' }"
            v-model="stamp"
            @fileRemovedAction="fileRemoved"
          />
          <span
            :class="{
              'input-instructions': !stampError,
              'input-instructions__error': stampError,
            }"
          >
            Upload a default image for this group.
          </span>
        </div>
        <div class="button-container">
          <PassportButton label="save group" class="save-button" @click="createGroup" />
          <PassportButton variant="text" class="cancel-button" @click="showModal = false" label="Cancel" />
        </div>
      </div>
    </PassportModal>
    <span class="page-heading"> Groups & Targets </span>
    <hr class="line-break" />
    <TargetTable ref="targetTable" heading="groups & targets" />
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';
import TargetTable from '@/components/TargetTable.vue';
import PassportModal from '@/components/PassportModal.vue';
import PassportInput from '@/components/PassportInput.vue';
import PassportImageUploader from '@/components/PassportImageUploader.vue';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '@/services/api.service';
import qrUtil from '@/utils/qr';

export default {
  name: 'default',
  components: {
    TargetTable,
    PassportButton,
    PassportModal,
    PassportInput,
    PassportImageUploader,
  },
  props: {},
  data() {
    return {
      groupId: uuidv4(),
      exRadioList: null,
      exChecked: false,
      selectedRows: [],
      showModal: false,
      groupName: '',
      isError: false,
      stamp: '',
      stampError: false,
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    fileRemoved(file) {
      if (file === 'stamp') {
        this.$refs.stampImage = null;
      }
    },
    ...qrUtil,
    async createGroup() {
      if (!this.$refs.stampImage.hasFile) {
        this.stampError = true;
      } else {
        this.stamp = this.$refs.stampImage.file.extension;
        this.stampError = false;
      }
      if (this.stampError) {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: 'Please fix the highlighted errors and try to save again.',
        });
      } else if (this.groupName === '') {
        this.isError = true;
        this.$notify({
          group: 'primary',
          type: 'error',
          text: 'Please fix the highlighted errors and try to save again.',
        });
      } else {
        if (this.stamp === 'jpeg') {
          this.stamp = 'jpg';
        }
        try {
          const { data } = await ApiService.post(`/targetGroup/${this.groupId}/asset`, {
            fileFormat: this.stamp,
          });
          this.iconSrc = data.assetUrl;
          await this.$refs.stampImage.postS3(data.presignedUrl);
        } catch (err) {
          console.error(err);
        }
        await ApiService.put(`/targetGroup/${this.groupId}`, {
          name: this.groupName,
          iconSrc: this.iconSrc,
          draft: true,
        }).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.$notify({
              group: 'primary',
              type: 'success',
              text: 'Group created successfully.',
            });
            this.showModal = false;
            this.groupName = '';
            this.$refs.targetTable.getGroups();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.examples {
  .actions {
    display: flex;
    justify-content: flex-end;
  }
  ::v-deep .base-file-upload .select-file {
    align-items: center;
    width: 19em;
    margin-bottom: 1em;
  }
  .stamp-uploader {
    margin-top: 1em;
    margin-right: 0.75em;
  }

  .line-break {
    width: 1601px;
    margin-top: 3em;
    margin-bottom: 3em;
    opacity: 0.1;
  }
  .page-heading {
    display: flex;
    font-size: 28px;
    letter-spacing: -0.7px;
  }
  .example {
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 10px;

    ::v-deep {
      .header {
        padding: 10px;
      }
      .collapse-wrapper > .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
}
::v-deep .passport-button.outline.primary {
  margin-right: 10px;
  font-size: 14px;
  text-transform: uppercase;
}
.new-target {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
  text-transform: uppercase;
}
::v-deep .g-input .input-element:not([type='submit'], [type='range']) {
  width: 315px;
  font-size: 18px;
}
.create-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  padding: 70px;
  background-image: url(../assets/images/bg_modal.png);
  background-repeat: no-repeat;
  background-size: cover;
  .input-header {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }
  .input-instructions {
    font-size: 14px;
    &__error {
      font-size: 14px;
      color: $red;
    }
  }
  .group-title {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
  }
  .save-button,
  .cancel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 136px;
    margin-top: 1em;
  }
  .save-button {
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 4px;
  }
  .modal-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    font-size: 34px;
  }
  .modal-separator {
    width: 200px;
    margin-bottom: 2em;
  }
  .choice-text {
    font-size: 16px;
    text-align: center;
  }
}
</style>
