import { ApiService } from '@/services/api.service';

export default {
  async downloadQRs({ targetGroup }) {
    const response = await ApiService.get(
      '/target',
      { limit: 10000, includeDraft: false, targetGroup: targetGroup || undefined },
      { responseType: 'arraybuffer', headers: { Accept: 'application/pdf' } },
    );
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    a.target = '_blank';
    a.click();
  },
};
